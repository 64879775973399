export const YokaiButton = (props: any) => {
  const { txt = "Btn", submit } = props;
  return (
    <div
      className="w-[211px] min-h-[88px] h-[88px] relative z-[5] flex flex-col items-center justify-center cursor-pointer"
      style={{
        backgroundImage: `url("/assets/Bt_1.png")`,
        backgroundSize: "cover",
      }}
      onClick={submit}
    >
      <p className="button-txt glowing-light">{txt}</p>
    </div>
  );
};
