import { YokaiSignUp } from "src/components/yokai-sign-up";
import { Container, VideoBg } from "../components";
import { useState } from "react";
import { YokaiRegister } from "src/components/yokai-register";
import { YokaiSubmitted } from "src/components/yokai-submitted";
import { YokaiNotApprove } from "src/components/yokai-not-approve";
import { YokaiApproved } from "src/components/yokai-approved";

export const Home = (props: any) => {
  const [stage, setStage] = useState("sign-in"); // sign-in,register,submitted,approved,not-approve

  console.log("Stage:", stage);
  return (
    <Container>
      <div className="home p-[30px] h-full bg-transparent flex items-center justify-center relative z-[5]">
        {/* Sign Up */}
        {stage === "sign-in" && <YokaiSignUp setStage={setStage} />}
        {/* Register */}
        {stage === "register" && <YokaiRegister setStage={setStage} />}
        {stage === "submitted" && <YokaiSubmitted setStage={setStage} />}
        {stage === "approved" && <YokaiApproved setStage={setStage} />}
        {stage === "not-approve" && <YokaiNotApprove setStage={setStage} />}
      </div>

      <VideoBg />
    </Container>
  );
};
