import { YokaiButton } from "./yokai-button";

export const YokaiNotApprove = (props: any) => {
  const { setStage } = props;
  return (
    <div className="relative z-[5] flex flex-col items-center">
      <img src="/assets/Icon_Hand_with_words.png" alt="hand" />
      <div
        className="w-[605px] h-[225px] relative z-[5] flex flex-col items-center p-[30px]"
        style={{
          backgroundImage: `url("/assets/Frame_3.png")`,
        }}
      >
        <p className="text-[36px] font-[600] glowing-light mt-[5px] opacity-[70%]">
          Not Approve
        </p>
      </div>
      <div className="relative bottom-[70px]">
        <YokaiButton txt="Home" submit={() => setStage("sign-in")} />
      </div>
    </div>
  );
};
