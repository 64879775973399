import { YokaiButton } from "./yokai-button";

export const YokaiSignUp = (props: any) => {
  const { setStage } = props;

  const submit = () => {
    setStage("register");
  };
  return (
    <div
      className="w-[609px] h-[578px] relative z-[5] flex flex-col items-center"
      style={{
        backgroundImage: `url("/assets/Frame_1.png")`,
      }}
    >
      <h1 className="pt-[40px] tracking-[5px] glowing-light">Yokai Nexus</h1>
      <img src="/assets/Icon_Goat.png" alt="goat" />
      <YokaiButton txt="Sign in" submit={() => submit()} />
    </div>
  );
};
