export const VideoBg = () => {
  return (
    <div className="video-container">
      {/* Video */}
      <video autoPlay muted loop className="video">
        <source src="/assets/bg_v2.mp4" type="video/mp4" />
      </video>
    </div>
  );
};
