import { useRegistrationStore } from "src/stores";
import { openErrorNotification } from "./notification";
import { YokaiButton } from "./yokai-button";
import Web3 from "web3";

export const YokaiRegister = (props: any) => {
  const { setStage } = props;
  const { register } = useRegistrationStore();

  async function connect() {
    // @ts-ignore: Unreachable code error
    const web3 = new Web3(window?.lukso);
    try {
      await web3.eth.requestAccounts();
      const accounts: any = await web3.eth.requestAccounts();
      const address = accounts[0];
      console.log("Account connected:", address);
      const result = await register({ address: address });
      if (result?.second_verify) return setStage("approved");
      if (result?.first_verify) return setStage("submitted");
      return setStage("not-approve");
    } catch (error) {
      // handle connection error
      console.log("Error in connecting account:", error);
      openErrorNotification("Please try to connect again later");
    }
  }

  const submit = async () => {
    await connect();
  };

  return (
    <div className="relative z-[5] flex flex-col items-center">
      <div
        className="w-[608px] h-[577px] flex flex-col p-[50px] pb-[0px]"
        style={{
          backgroundImage: `url("/assets/Frame_2.png")`,
        }}
      >
        <p className="tracking-[5px] italic">Instruction</p>
        <h1 className="uppercase text-right tracking-[5px] glowing-light mt-[20px] !mb-[0px]">
          How To WhiteList
        </h1>
        <p className="tracking-[5px] italic button-txt !mt-[0px] text-right">
          Yokai Nexus
        </p>

        <p className="mt-[20px] text-[16px] text-center">
          <span className={$hightlight}>Grant access</span> of the revered
          artifact, "Angel Eyes" to our futuristic machine and unlock{" "}
          <span className={$hightlight}>the whitelist benefits</span> for
          summoning Lyxen Demons to the Land of Luminara.
        </p>

        <p className="mt-[20px] text-[16px] text-center">
          <span className={$hightlight}>Safeguard</span> the artifact as your
          key <span className={$hightlight}>until 10th Feb</span>. Stay attuned
          to its energy. Be aware of{" "}
          <span className={$hightlight}>random snapshots</span> for your final
          summoning <span className={$hightlight}>eligibility</span>. May your
          journey be filled with wonder, discovery, and harmony.
        </p>
      </div>
      <div className="relative bottom-[70px]">
        <YokaiButton txt="My Status" submit={() => submit()} />
      </div>
    </div>
  );
};

const $hightlight = "text-primary font-[700]";
